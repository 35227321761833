.row {
    content: "";
    display : table;
    clear : both;
    padding-bottom: .25em;
    width:100%
  }

  .column {
    float : left;
    width : 35%;
    padding : .5em;
  }

  .instructor-column {
    float : left;
    height: 25em;
    width : 45%;
    padding-top : .1em;
    padding-left: .25em;
  }

.selected-player {
    background-color: black;
    color:whitesmoke;
    font-weight: bolder;
}

.match {
    float: left;
    margin: .5em;
    height: 14em;
    width: 19em;
    border: red;
    border-style:groove;
    background-color:rgb(245, 226, 226);
}

.match-complete{
    background-image: url("../images/siora-photography-MBOiT6Ee-_I-unsplash.jpg");
    /* background-color: gold; */
}

.match-first {
    font-size:large;
    font-weight: bold;
    /* margin-top: 7em; */
    padding-top: 1em;
    padding-bottom: .2em;
}

.match-line {
    margin: .25em;
}

.hide {
    display : none;
}

.title {
    font-weight: bold;
    margin: .25em;
    width: 95%;
    font-size: 2em;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-style: italic;

}
.available {
    background-color:mediumaquamarine;
}

.scheduled {
    background-color: antiquewhite;
}

img {
    border-radius: 50%;
    height: 150px;
    width: 125px;
    float: left;
    margin: .25em;
    margin-top: .7em;
}

.player-col {
    float:left;
    width : 45%;
    text-align: left;
    margin-left: .5em;
}

h3 {
    margin-bottom: .25em;
    width: 100%;
    text-align:center;
}

ul {
    display: block;
    float:left;
    text-align: left;
}

.vs {
    float: left;
    width: 3%;
    height: 5em;
    padding-top: 5em;
    font-size: larger;
    font-weight: 700;
    
}

.draw-line {
    float: left;
    width: 95%;
    border: black;
    border-style:groove;
    border-radius: 1em;
    margin: 1em;
}